import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { ProductService } from '../../service/ProductService';
import {Divider} from "primereact/divider";
import {Calendar} from "primereact/calendar";
import {Fieldset} from "primereact/fieldset";


const Dashboard = (props) => {
    const [products, setProducts] = useState(null);
    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const [lineOptions, setLineOptions] = useState(null)
    const [date9, setDate9] = useState(new Date());
    const [bntDisabled, setBntDisabled] = useState(true);
    let oldDate = null;

    const advNum = [234, 450, 300, 100];
    const cashNum = [120, 280];

    const advColors = ['#CA054D', '#ECC30B', '#A4D4B4', '#FFCF9C'];
    const cashColors = ['#84BCDA'];

    const adv = ['ТОП статус', 'Баннеры', 'Ветки на форуме', 'Рейтинг товаров'];
    const cash = ['Вывод'];

    let advData = { labels: [], datasets: [] };
    let cashData = { labels: [], datasets: [] };

    for (let c=0; c<adv.length; c++){
        advData.datasets.push({ label: adv[c], data: [], fill: false, backgroundColor: advColors[c], borderColor: advColors[c], tension: .4 });
    }
    for (let b=0; b<cash.length; b++){
        cashData.datasets.push({ label: cash[b], data: [], fill: false, backgroundColor: cashColors[b], borderColor: cashColors[b], tension: .4 });
    }

    for (let i=0; i<30; i++){
        advData.labels.push(i+1);
        cashData.labels.push(i+1);
        if (date9.getDay() < i ){
            for (let ii=0; ii<adv.length; ii++){
                advData.datasets[ii].data.push(Math.round(advNum[ii]+ advNum[ii] * (Math.random() - .5)) );
            }

            for (let bb=0; bb<cash.length; bb++) {
                cashData.datasets[bb].data.push(Math.round(cashNum[bb]+ cashNum[bb] * (Math.random() - .5)) );
            }
        }
    }

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    useEffect(() => {
        const productService = new ProductService();
        productService.getProductsSmall().then(data => setProducts(data));
    }, []);

    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const setNewData = e => {
        if (date9 ===  e.value) return;
        if(oldDate == null) {
            oldDate = date9;
            setBntDisabled(false);
        }
        setDate9(e.value);
    }

    const updateData = () => {
        setBntDisabled(true);
        oldDate = null;
    }


    return (
        <div className="grid">
            <div className="col-12 xl:col-6">
                <div className="card">
                    <div className="flex justify-content-between align-items-center flex-wrap">
                        <h6 className={'mb-2 md:mb-0 font-bold min-w-full md:min-w-0 text-center'}>Отчетный период</h6>
                        <Calendar id="monthpicker" value={date9} onChange={setNewData} view="month" dateFormat="mm/yy" inputClassName={'text-center'}/>
                        <Button label="ПРИМЕНИТЬ" className="p-button-outlined" disabled={bntDisabled} onClick={updateData}/>
                    </div>
                </div>
            </div>
            <div className="col-12 xl:col-6">
                <div className="card">
                    <div className="flex justify-content-between align-items-center flex-wrap">
                        <h6 className={'mb-2 md:mb-0 font-bold min-w-full md:min-w-0 text-center'}>Общий баланс</h6>
                        <div className={'md:text-center text-left'}>
                            <div className="text-900 font-medium text-xl">$245.100</div>
                            <span className="text-500">всех кошельков платформы по текущему курсу</span>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-dollar text-green-500 text-xl"/>
                        </div>
                    </div>
                </div>
            </div>

            <Divider align="center">
                <span className="p-tag"><h5 className="mb-0 font-bold">Доходы $68.391</h5></span>
            </Divider>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Регистрация</span>
                            <div className="text-900 font-medium text-xl">$2.100</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-user-plus text-blue-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">12 новых </span>
                    <span className="text-500">за отчетный период</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Штрафы</span>
                            <div className="text-900 font-medium text-xl">$1.350</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-exclamation-circle text-orange-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">8 штрафов </span>
                    <span className="text-500">за отчетный период</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Обменка</span>
                            <div className="text-900 font-medium text-xl">$28.441</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-percentage text-cyan-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">+8%  </span>
                    <span className="text-500">по отношению к прошлому</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Абонплата</span>
                            <div className="text-900 font-medium text-xl">$17.250</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-money-bill text-purple-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">+4% </span>
                    <span className="text-500">по отношению к прошлому</span>
                </div>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Реклама $5.450</h5>
                    <Chart type="line" data={advData} options={lineOptions} />
                </div>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Вывод средств $13.800</h5>
                    <Chart type="line" data={cashData} options={lineOptions} />
                </div>
            </div>

            <Divider align="center">
                <span className="p-tag"><h5 className="mb-0 font-bold">Расходы $36.422</h5></span>
            </Divider>
            <Fieldset legend="Сотрудники ЗП $15.150" className={"col-12 staff-area"}>
                <div className="col-6 lg:col-4 xl:col-3">
                    <div className="card mb-0 flex justify-content-between mb-3 align-items-center staff-area-item">
                            <div>
                                <span className="block text-500 font-medium mb-3">Модераторы</span>
                                <div className="text-900 font-medium text-xl">$1.500</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                                <i className="pi pi-pencil text-blue-500 text-xl"/>
                            </div>
                    </div>
                </div>
                <div className="col-6 lg:col-4 xl:col-3">
                    <div className="card mb-0 flex justify-content-between mb-3 align-items-center staff-area-item">
                        <div>
                            <span className="block text-500 font-medium mb-3">Девелопмент</span>
                            <div className="text-900 font-medium text-xl">$6.250</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-server text-blue-500 text-xl"/>
                        </div>
                    </div>
                </div>
                <div className="col-6 lg:col-4 xl:col-3">
                    <div className="card mb-0 flex justify-content-between mb-3 align-items-center staff-area-item">
                        <div>
                            <span className="block text-500 font-medium mb-3">СММ</span>
                            <div className="text-900 font-medium text-xl">$1.400</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-chart-line text-blue-500 text-xl"/>
                        </div>
                    </div>
                </div>
                <div className="col-6 lg:col-4 xl:col-3">
                    <div className="card mb-0 flex justify-content-between mb-3 align-items-center staff-area-item">
                        <div>
                            <span className="block text-500 font-medium mb-3">Тех поддержка</span>
                            <div className="text-900 font-medium text-xl">$3.000</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-phone text-blue-500 text-xl"/>
                        </div>
                    </div>
                </div>
                <div className="col-6 lg:col-4 xl:col-3">
                    <div className="card mb-0 flex justify-content-between mb-3 align-items-center staff-area-item">
                        <div>
                            <span className="block text-500 font-medium mb-3">Дизайнеры</span>
                            <div className="text-900 font-medium text-xl">$1.000</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-palette text-blue-500 text-xl"/>
                        </div>
                    </div>
                </div>
                <div className="col-6 lg:col-4 xl:col-3">
                    <div className="card mb-0 flex justify-content-between mb-3 align-items-center staff-area-item">
                        <div>
                            <span className="block text-500 font-medium mb-3">Верстальщики</span>
                            <div className="text-900 font-medium text-xl">$2.000</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-code text-blue-500 text-xl"/>
                        </div>
                    </div>
                </div>
            </Fieldset>

            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Кадровые</span>
                            <div className="text-900 font-medium text-xl">$9.000</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-yellow-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-users text-yellow-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-500 font-medium">0% </span>
                    <span className="text-500">по отношению к прошлому</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Обменка</span>
                            <div className="text-900 font-medium text-xl">$3.736</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-money-bill text-purple-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">+2% </span>
                    <span className="text-500">по отношению к прошлому</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Рекламные</span>
                            <div className="text-900 font-medium text-xl">$6.320</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-compass text-cyan-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-pink-500 font-medium">-3%  </span>
                    <span className="text-500">по отношению к прошлому</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Другое</span>
                            <div className="text-900 font-medium text-xl">$2.216</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-info text-orange-500 text-xl"/>
                        </div>
                    </div>
                    <span className="text-pink-500 font-medium">-1% </span>
                    <span className="text-500">по отношению к прошлому</span>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Dashboard);
