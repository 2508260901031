import React, {useEffect, useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {CustomerService} from "../../service/CustomerService";

const FormLayoutDemo = () => {
    const [customers3, setCustomers3] = useState([]);

    const customerService = new CustomerService();

    useEffect(() => {
        customerService.getCustomersMedium().then(data => setCustomers3(data));

        initFilters1();
    }, []);


    const initFilters1 = () => {
    }

    const countryBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <img alt="flag" src="/assets/demo/images/flags/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} width={30} />
                <span style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className="image-text">{rowData.country.name}</span>
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>;
    }

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <img alt={data.representative.name} src={`/images/avatar/${data.representative.image}`} width="32" style={{ verticalAlign: 'middle' }} />
                <span className="image-text font-bold">{data.representative.name}</span>
            </React.Fragment>
        );
    }

    const footerTemplate = (data) => {
        return (
            <React.Fragment>
                <td colSpan="4" style={{ textAlign: 'right' }} className="text-bold pr-6">Total Customers</td>
                <td>{calculateCustomerTotal(data.representative.name)}</td>
            </React.Fragment>
        );
    }

    const calculateCustomerTotal = (name) => {
        let total = 0;

        if (customers3) {
            for (let customer of customers3) {
                if (customer.representative.name === name) {
                    total++;
                }
            }
        }

        return total;
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card" style={{ height: 'calc(100vh - 126px)' }}>
                    <DataTable value={customers3} rowGroupMode="subheader" groupRowsBy="representative.name"
                               sortMode="single" sortField="representative.name" sortOrder={1} scrollable scrollHeight="flex"
                               rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate} responsiveLayout="scroll">
                        <Column field="company" header="Компания" style={{ minWidth: '200px' }}></Column>
                        <Column field="country" header="Страна" body={countryBodyTemplate} style={{ minWidth: '200px' }}></Column>
                        <Column field="name" header="Имя" style={{ minWidth: '200px' }}></Column>
                        <Column field="status" header="Статус" body={statusBodyTemplate} style={{ minWidth: '200px' }}></Column>
                        <Column field="date" header="Дата" style={{ minWidth: '200px' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    )
}

export default React.memo(FormLayoutDemo);
