import React from 'react';

const EmptyPage = () => {

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Coming soon</h5>

                </div>
            </div>
        </div>
    );
}


export default React.memo(EmptyPage);
