import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {Dropdown} from "primereact/dropdown";
import {OverlayPanel} from "primereact/overlaypanel";
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import {Divider} from "primereact/divider";
import {InputSwitch} from "primereact/inputswitch";
import MenuItemTemplate from "./components/menu/MenuItemTemplate";
import {Menubar} from "primereact/menubar";

export const AppTopbar = (props) => {
    const op = useRef(null);
    const [theme, setTheme] = useState(localStorage.getItem('colorMode') === 'true' );

    useEffect(() => {
        props.onColorModeChange(theme ? 'light' : 'dark');

        let themeElement = document.getElementById('theme-link');
        const themeHref = '/assets/themes/' + (theme ? 'lara-light-indigo' : 'vela-blue') + '/theme.css';
        replaceLink(themeElement, themeHref, null);

    },[theme, replaceLink]);

    const dropdownValues = [
        {
            label: 'Germany', code: 'DE',
            items: [
                { label: 'Berlin', value: 'Berlin' },
                { label: 'Frankfurt', value: 'Frankfurt' },
                { label: 'Hamburg', value: 'Hamburg' },
                { label: 'Munich', value: 'Munich' }
            ]
        },
        {
            label: 'USA', code: 'US',
            items: [
                { label: 'Chicago', value: 'Chicago' },
                { label: 'Los Angeles', value: 'Los Angeles' },
                { label: 'New York', value: 'New York' },
                { label: 'San Francisco', value: 'San Francisco' }
            ]
        },
        {
            label: 'Japan', code: 'JP',
            items: [
                { label: 'Kyoto', value: 'Kyoto' },
                { label: 'Osaka', value: 'Osaka' },
                { label: 'Tokyo', value: 'Tokyo' },
                { label: 'Yokohama', value: 'Yokohama' }
            ]
        }
    ];

    const items = [
        {
            label: 'Покупатель',
            items: [
                {
                    label: 'Профиль',
                    icon: 'pi pi-user',
                    command: () => {},
                    template: (item, options) => MenuItemTemplate(item, options, onMenuItemClick)
                },
                {
                    label: 'Покупки',
                    icon: 'pi pi-shopping-cart',
                    command: () => {},
                    template: (item, options) => MenuItemTemplate(item, options, onMenuItemClick)
                },
                // {
                //     label: 'Войти',
                //     icon: 'pi pi-sign-in',
                //     command: () => op.current.hide(),
                // },
                {
                    label: 'Выйти',
                    icon: 'pi pi-sign-out',
                    command: () => {},
                    template: (item, options) => MenuItemTemplate(item, options, onMenuItemClick)
                }
            ]
        },
        {
            label: 'Магазин',
            items: [
                {
                    label: 'Админ панель',
                    icon: 'pi pi-cog',
                    to: '/admin',
                    template: (item, options) => MenuItemTemplate(item, options, onMenuItemClick)
                }
            ]
        }
    ];

    const nestedMenuitems = [
        {
            label: 'Главная',
            icon: 'pi pi-home',
            to: '/home',
            template: (item, options) => MenuItemTemplate(item, options)
        },
        {
            label: 'Товары',
            icon: 'pi pi-box',
            to: '/products',
            template: (item, options) => MenuItemTemplate(item, options)
        },
        {
            label: 'Магазины',
            icon: 'pi pi-shopping-bag',
            to: '/shops',
            template: (item, options) => MenuItemTemplate(item, options)
        },
        {
            label: 'Работа',
            icon: 'pi pi-compass',
            to: '/work',
            template: (item, options) => MenuItemTemplate(item, options)
        },
    ];

    const [dropdownValue, setDropdownValue] = useState(dropdownValues[0].items[0].label);

    const onMenuItemClick = (e) => {
        op.current.hide();
    }

    const replaceLink = useCallback((linkElement, href, callback) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
            if (callback) callback();
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) callback();
            });
        }
    },[]);

    const isIE = () => /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);

    const switchTheme = (e) => {
        localStorage.setItem('colorMode', e.value);
        setTheme(e.value)
    }

    const selectedCityTemplate = (option, props) => (
        <div className="city-item-value flex flex-column">
            <span className={"text-center"}>Ваш город</span>
            {
                option
                    ? <span className={"text-center p-text-primary font-semibold"}>{option.label}</span>
                    : <span className={"text-center font-semibold"}>{props.placeholder}</span>
            }
        </div>
    );

    const groupedItemTemplate = (option) => {
        return (
            <div className="flex align-items-center country-item">
                <img alt={option.label} src="../public/images/flag/flag_placeholder.png" onError={(e) => e.target.src = ''} className={`flag flag-${option.code.toLowerCase()}`} />
                <div>{option.label}</div>
            </div>
        );
    }

    return (
        <div className="layout-topbar">
            <div className={"layout-topbar-left"}>
                <Link to="/" className="layout-topbar-logo">
                    <img src={props.layoutColorMode === 'light' ? '/images/logo-dark.svg' : '/images/logo-light.svg'} alt="logo"/>
                    {/*<span>kraken</span>*/}
                </Link>

                {
                    props.isSideMenu
                    ? <>
                        <button type="button" className="p-link layout-menu-button layout-topbar-button"
                                onClick={props.onToggleMenuClick}>
                            <i className="pi pi-bars"/>
                        </button>

                        <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                        <i className="pi pi-ellipsis-v" />
                        </button>
                    </>
                    : null
                }
            </div>

            <Menubar model={nestedMenuitems}></Menubar>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li>
                    <Dropdown value={dropdownValue} onChange={(e) => setDropdownValue(e.value)} valueTemplate={selectedCityTemplate} filter showFilterClear resetFilterOnHide options={dropdownValues} optionLabel="label" optionGroupLabel="label" optionGroupChildren="items"
                              optionGroupTemplate={groupedItemTemplate} placeholder="Выберите город" dropdownIcon={'pi pi-map-marker p-text-secondary text-2xl'} className={"dropdown-city"} appendTo={'self'}/>
                </li>
                <li>
                    <Button type="button" onClick={(e) => op.current.toggle(e)} aria-haspopup aria-controls="user_menu_panel" className="p-link layout-topbar-button">
                        <i className="pi pi-user"/>
                        <span>Профиль</span>
                    </Button>
                    {/*<Menu model={items} popup ref={op} id="user_menu_panel" className="topbar-user-menu" appendTo={'self'}/>*/}
                    <OverlayPanel ref={op} id="user_menu_panel" className="topbar-user-menu" appendTo={'self'}>
                        <div className={'user-menu-info p-mi flex flex-column'}>
                            <span className={'font-bold mb-2'}>Louis C.K.</span>
                            <Button className="balance p-0" aria-label="пополнить" onClick={(e) => op.current.hide(e)}>
                                <i className="pi pi-wallet h-full flex align-items-center justify-content-center"/>
                                <span className="action w-full font-semibold">пополнить</span>
                                <span className="value w-full font-semibold">35,670 ₽</span>
                            </Button>
                        </div>
                        <Divider className={"m-0"}/>
                        <Menu model={items} />
                        <Divider className={"m-0"}/>
                        <div className={'p-mi flex align-items-center justify-content-between font-semibold'}>
                            <span>{`${theme ? 'Темный' : 'Светлый'} режим`}</span>
                            <InputSwitch checked={theme} onChange={switchTheme} />
                        </div>
                    </OverlayPanel>
                </li>
            </ul>
        </div>
    );
}
