import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'primereact/button';
// material
import {Box, Card, Link, Typography, Stack, Avatar} from '@mui/material';
import {alpha, styled} from '@mui/material/styles';
// utils
import { fCurrency } from '../utils/formatNumber';
// components
import Label from './Label';
import { ColorPreview } from './color-utils';
import SvgIconStyle from "./SvgIconStyle";

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)',
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 48,
    height: 48,
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    bottom: theme.spacing(-2.5),
}));

const CoverImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});

// ----------------------------------------------------------------------

TopAdCard.propTypes = {
  product: PropTypes.object,
};

export default function TopAdCard({ product }) {
  const { cover, title, author } = product;

  return (
    <div className={'card p-0 overflow-hidden'}>
        <Box sx={{ pt: '50%', position: 'relative' }}>
            <SvgIconStyle
                color="paper"
                src="/static/icons/shape-avatar.svg"
                sx={{
                    width: 122,
                    height: 56,
                    zIndex: 9,
                    bottom: -24,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    left: 0,
                    right: 0,
                    position: 'absolute',
                    color: 'var(--surface-card)',
                }}
            />
            <AvatarStyle alt={author.name} src={author.avatarUrl} />
            <CoverImgStyle alt={author.name} src={cover} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <div className={'flex flex-column justify-content-between max-h-3rem h-3rem'}>
            <div className={'white-space-nowrap font-bold text-overflow-ellipsis surface-overlay overflow-hidden text-center'}>
                {author.name}
            </div>
            <div className={'white-space-nowrap font-semibold text-overflow-ellipsis surface-overlay overflow-hidden text-center'}>
                {title}
            </div>
        </div>
      </Stack>
    </div>
  );
}
// white-space-nowrap
