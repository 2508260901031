import React, { useState, useEffect, useRef } from 'react';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';

import PrimeReact, {addLocale, locale} from 'primereact/api';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';

import classNames from 'classnames';
import {matchPath, Routes, Route, useLocation, Navigate} from 'react-router-dom';

import { CSSTransition } from 'react-transition-group';
import { AppTopbar } from './AppTopbar';
import { Tooltip } from 'primereact/tooltip';

import {AppMenu} from "./AppMenu";
import EmptyPage from "./pages/EmptyPage";
import Dashboard from "./pages/admin/Dashboard";
import ChartDemo from "./pages/admin/ChartDemo";
import TableDemo from "./pages/admin/TableDemo";
import FormLayoutDemo from "./pages/admin/FormLayoutDemo";
import InputDemo from './pages/admin/InputDemo';
import Home from "./pages/shop/Home";
import Products from "./pages/shop/Products";

const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const isSideMenu = location.pathname.includes('admin');

    PrimeReact.ripple = true;
    document.documentElement.style.fontSize = '14px';

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();

    }, [location]);


    addLocale('ru', {
        firstDayOfWeek: 1,
        dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        dayNamesShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Су'],
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ной', 'Дек'],
        today: 'Сегодня',
        clear: 'Очистить',
        dateFormat:	'dd/mm/yy',
    });
    locale('ru');

    const menu = [
        {
            label: 'Статистика',
            items: [
                { label: 'Финансы', icon: 'pi pi-fw pi-dollar', to: '/admin/dashboard' },
                { label: 'Активность', icon: 'pi pi-fw pi-chart-pie', to: '/admin/activity' }
            ]
        },
        {
            label: 'Маркетплейс', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: 'Клиенты', icon: 'pi pi-fw pi-user', to: '/admin/clients' },
                { label: 'Магазины', icon: 'pi pi-fw pi-shopping-cart', to: '/admin/shops' },
                { label: 'Покупатели', icon: 'pi pi-fw pi-users', to: '/admin/buyers' },
            ]
        },
    ];

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': (staticMenuInactive && layoutMode === 'static') || !isSideMenu,
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });


    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar isSideMenu={isSideMenu} onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange}
                mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            {isSideMenu
                ? <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode}/>
                </div>
                : null
            }

            <div className="layout-main-container">
                <div className="layout-main">
                    <Routes>
                        <Route path='/'>
                            <Route index element={ <Navigate to="home" />} />
                            <Route path="home" element={<Home/>} />
                            <Route path="products" element={<Products/>} />
                            <Route path="shops" element={<EmptyPage/>} />
                            <Route path="work" element={<EmptyPage/>} />
                        </Route>
                        <Route path="/admin" >
                            <Route index element={ <Navigate to="dashboard" />} />
                            <Route path="dashboard" element={<Dashboard colorMode={layoutColorMode} location={location} />} />
                            <Route path="activity" element={<ChartDemo colorMode={layoutColorMode} location={location} />}/>
                            <Route path="clients" element={<TableDemo/>} />
                            <Route path="shops" element={<FormLayoutDemo/>} />
                            <Route path="buyers" element={<InputDemo/>} />
                        </Route>
                        <Route path="*" element={<EmptyPage/>} />
                    </Routes>
                </div>
            </div>


            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App;
