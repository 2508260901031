import React from 'react';
import {Container, Grid, Stack, Typography} from '@mui/material';
import PRODUCTS from '../../_mock/products';
import ShopProductCard from "../../components/ProductCard";
import {InputText} from "primereact/inputtext";

const Products = () => {
    return (
        <div>
            <div className={'mb-3 flex justify-content-between align-items-center'}>
                <h3 className={'m-0'}> Товары </h3>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="text" placeholder="Поиск" />
                </span>
            </div>
            <div className={'grid'}>
                {PRODUCTS.map((product) => (
                    <div key={product.id} className={'col-12 md:col-6 lg:col-4 xl:col-3'}>
                        <ShopProductCard product={product} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default React.memo(Products);
