import React from 'react';
import {Badge} from "primereact/badge";
import {Ripple} from "primereact/ripple";
import {NavLink} from "react-router-dom";

const MenuItemTemplate = (item, options, onMenuClickHandler) => {

    const onMenuItemClick = (event, item) => {
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        if (item.command) {
            item.command({originalEvent: event, item: item});
        }

        if (onMenuClickHandler) {
            onMenuClickHandler({ originalEvent: event, item: item });
        }
    }

    const onKeyDown = (event) => {
        if (event.code === 'Enter' || event.code === 'Space') {
            event.preventDefault();
            event.target.click();
        }
    }

    const renderLinkContent = (item) => {
        let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
        let badge = item.badge && <Badge value={item.badge}/>

        return (
            <>
                <span className={"p-menuitem-icon "+item.icon}></span>
                <span>{item.label}</span>
                {submenuIcon}
                {badge}
                <Ripple/>
            </>
        );
    }

    let content = renderLinkContent(item);

    if (item.to) {
        return (
            <NavLink aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" className="p-menuitem-link p-ripple"
                      to={item.to}
                     onClick={(e) => onMenuItemClick(e, item)} target={item.target}>
                {content}
            </NavLink>
        )
    } else {
        return (
            <a tabIndex="0" aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" href={item.url}
               className="p-menuitem-link p-ripple" onClick={(e) => onMenuItemClick(e, item)} target={item.target}>
                {content}
            </a>
        );
    }
}

export default MenuItemTemplate;
