import React, { useState, useEffect, useRef } from 'react';
import POSTS from '../../_mock/blog';
import {Grid} from "@mui/material";
import BlogPostCard from "../../components/BlogPostCard";
import PRODUCTS from "../../_mock/products";
import TOPAD from '../../_mock/topad';
import TOPADSEC from '../../_mock/topadsec';
import TopAdCard from "../../components/TopAdCard";
import {Carousel} from "primereact/carousel";
import {Image} from "primereact/image";
import ShopProductCard from "../../components/ProductCard";
import {Divider} from "primereact/divider";

const Home = () => {
    const responsiveOptions = [
        {
            breakpoint: '992px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '768px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '480px',
            numVisible: 2,
            numScroll: 1
        }
    ];
    const productTemplate = (product) => {
        return (
            <div className="card p-0 m-2 overflow-hidden">
                <img height={120} width={'100%'} src={product.url} alt={product.name} style={{objectFit:'cover', marginBottom: '-3px',}}/>
            </div>
        )
    }

    return(
        <div className={'page-home'}>
            <div className={'grid mb-2'}>
                {TOPAD.slice(0, 6).map((product) => (
                    <div key={product.id} className={'col-6 md:col-4 lg:col-2'}>
                        <TopAdCard product={product} />
                    </div>
                ))}
            </div>
            <Carousel value={TOPADSEC} page={0} itemTemplate={productTemplate} numVisible={5} numScroll={1} responsiveOptions={responsiveOptions} circular autoplayInterval={2500}/>

            {/*<h3 className={'m-0'}> Топ продаж </h3>*/}
            <Divider align="center">
                <span className="p-tag"><h5 className="mb-0 font-bold">ТОП ПРОДАЖ</h5></span>
            </Divider>

            <div className={'grid'}>
                {PRODUCTS.slice(12, 24).map((product) => (
                    <div key={product.id} className={'col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2'}>
                        <ShopProductCard product={product} />
                    </div>
                ))}
            </div>

            <Divider align="center" className={'mt-6'}>
                <span className="p-tag"><h5 className="mb-0 font-bold">ТОП ПРОДАВЦОВ</h5></span>
            </Divider>
            <div className={'grid mb-2'}>
                {TOPAD.slice(6, 12).map((product) => (
                    <div key={product.id} className={'col-6 md:col-4 lg:col-2'}>
                        <TopAdCard product={product} />
                    </div>
                ))}
            </div>

            <Divider align="center" className={'mt-6'}>
                <span className="p-tag"><h5 className="mb-0 font-bold">СО СКИДКОЙ</h5></span>
            </Divider>

            <div className={'grid'}>
                {PRODUCTS.slice(0, 12).map((product) => (
                    <div key={product.id} className={'col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2'}>
                        <ShopProductCard product={product} />
                    </div>
                ))}
            </div>
        </div>

        // <Grid container spacing={3}>
        //     {POSTS.map((post, index) => (
        //         <BlogPostCard key={post.id} post={post} index={index} />
        //     ))}
        //
        // </Grid>
    )
}

export default React.memo(Home);
