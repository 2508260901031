import { faker } from '@faker-js/faker';
import { sample } from 'lodash';
import {getHoverColor} from "chart.js/helpers";

// ----------------------------------------------------------------------

const PRODUCT_NAME = [
  'Метадон',
  'Бошки',
  'A-PVP',
  'БЕЛЫЙ КРИСТАЛЛ',
  'Кристалл СИНИЙ',
  'PVP CrystaL White',
  'MEPHEDRON',
  'Diamond',
  'Экстази',
  'Грибы',
  'Гашишь',
  'Кокаин VHQ',
  'MEPH',
  'Мефедрон',
  'AMF',
  'Ecstasy',
  'Метадон',
  'Бошки',
  'A-PVP',
  'БЕЛЫЙ КРИСТАЛЛ',
  'Кристалл СИНИЙ',
  'PVP CrystaL White',
  'MEPHEDRON',
  'Diamond',
  'Экстази',
  'Грибы',
  'Гашишь',
  'Кокаин VHQ',
  'MEPH',
  'Мефедрон',
  'AMF',
  'Ecstasy',
];
const PRODUCT_COLOR = ['1 г.', '1,5 г.', '2 г.', '1 шт.', '2 шт.', '3 шт.', '5 г.', '10 г.'];

// ----------------------------------------------------------------------

const products = [...Array(24)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    cover: `/static/mock-images/products/product_${setIndex}.jpg`,
    name: PRODUCT_NAME[index],
    price: faker.datatype.number({ min: 1200, max: 5000, precision: 0.01 }),
    priceSale: setIndex % 3 ? null : faker.datatype.number({ min: 2000, max: 3500, precision: 0.01 }),
    colors: sample(PRODUCT_COLOR),
    status: sample(['СКИДКА', 'НОВЫЙ', '', '']),
    author: {
      name: faker.name.findName(),
      avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.png`,
    },
  };
});

export default products;
