import { faker } from '@faker-js/faker';

// ----------------------------------------------------------------------

const POST_TITLES = [
    '✨ Лучшие ✨',
    'Наход в касание всегда!',
    'Всегда высшее качество',
    'Ведется набор сотрудников',
    'На этой неделе распродажа',
    'С каждой покупкой подарок',
    'Работаем во всех районах',
    'Скидки! Скидки! Скидки!',
    'Участвуй в нашем розыгрыше',
    '✨ Лучшие ✨',
    'Наход в касание всегда!',
    'Всегда высшее качество',
    'Ведется набор сотрудников',
    'На этой неделе распродажа',
    'С каждой покупкой подарок',
    'Whiteboard Templates By Industry Leaders',
    'Tesla Cybertruck-inspired camper trailer for Tesla fans who can’t just wait for the truck!',
    'Designify Agency Landing Page Design',
    'vincenzo de cotiis’ crossing over showcases a research on contamination',
    'Simple, Great Looking Animations in Your Project | Video Tutorial',
    '40 Free Serif Fonts for Digital Designers',
    'Examining the Evolution',
    'Katie Griffin loves making',
    'The American Dream retold through',
    'How to create a client-serverless Jamstack app using Netlify, Gatsby and Fauna',
    'Tylko Organise effortlessly -3D & Motion Design',
    'RAYO ?? A expanded visual arts festival identity',
    'Anthony Burrill and Wired mag’s Andrew Diprose discuss how they made January’s Change Everything cover',
    'Inside the Mind of Samuel Day',
    'Portfolio Review: Is This Portfolio Too Creative?',
    'Here’s a Dyson motorcycle concept that doesn’t ‘suck’!',
    'How to Animate a SVG with border-image',
];

const topad = [...Array(24)].map((_, index) => ({
    id: faker.datatype.uuid(),
    cover: `/static/mock-images/covers/cover_${index + 1}.jpg`,
    title: POST_TITLES[index],
    author: {
        name: faker.company.companyName(1),
        avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.png`,
    },
}));

export default topad;
