import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'primereact/button';
// material
import {Box, Card, Link, Typography, Stack, Avatar} from '@mui/material';
import {alpha, styled} from '@mui/material/styles';
// utils
import { fCurrency } from '../utils/formatNumber';
// components
import Label from './Label';
import { ColorPreview } from './color-utils';
import SvgIconStyle from "./SvgIconStyle";

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)',
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    left: theme.spacing(3.3),
    bottom: theme.spacing(-2),
}));

const BuyBtnStyle = styled(Button)(({ theme }) => ({
    zIndex: 9,
    // width: 32,
    // height: 32,
    position: 'absolute',
    right: theme.spacing(3.1),
    bottom: theme.spacing(-3.1),
    height: '3rem!important',
    width: '3rem!important',
}));

const CoverImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, cover, price, colors, status, priceSale, author } = product;

  return (
    <div className={'card p-0 overflow-hidden'}>
        <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
            <Label
                variant="filled"
                color={(status === 'СКИДКА' && 'error') || 'info'}
                sx={{
                  zIndex: 9,
                  top: 16,
                  right: 16,
                  position: 'absolute',
                  textTransform: 'uppercase',
                }}
            >
              {status}
            </Label>
        )}
        <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 85,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              color: 'var(--surface-card)',
            }}
        />
        <AvatarStyle alt={author.name} src={author.avatarUrl} />
        <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
                width: 92,
                height: 40,
                zIndex: 9,
                bottom: -17,
                right: 0,
                position: 'absolute',
                color: 'var(--surface-card)',
            }}
        />
        <BuyBtnStyle icon="pi pi-shopping-cart" className="p-button-rounded p-button-success absolute" aria-label="Search" />
        <CoverImgStyle alt={name} src={cover} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <div className={'white-space-nowrap font-bold text-overflow-ellipsis surface-overlay overflow-hidden'}>
            {name}
          </div>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <div>{colors}</div>
            <div className={'price font-semibold'}>
                {priceSale ? <><span className={'line-through text-500 font-medium'}>{fCurrency(priceSale)}</span> {' '} </> : null}
                {fCurrency(price)}
            </div>
        </Stack>
      </Stack>
    </div>
  );
}
